html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  color: #4a5568;
  line-height: 1.425;
  background: #f6f9fc;
  font-family: "Open Sans", sans-serif;
}

.App {
  max-width: 1080px;
  min-width: 320px;
  padding: 0 2rem;
  margin: 0 auto;
}

.navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 5rem;
  padding: 0 1rem;
}

.navbar-brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50%;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 4px 0 rgba(50, 50, 93, 0.1);
  box-shadow: 0 2px 4px 0 rgba(50, 50, 93, 0.1);
}

.navbar-img {
  width: 90%;
}

.navbar-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-item {
  list-style: none;
}

.nav-item:not(:last-child) {
  margin-right: 1.75rem;
}

.nav-link {
  text-decoration: none;
  color: #4a5568;
  font-weight: 600;
}

.hero {
  height: -webkit-calc(100vh - 12.5rem);
  height: calc(100vh - 12.5rem);
  max-height: 50rem;
  min-height: 25rem;
  /* width: 70%; */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-text {
  width: 100%;
  max-width: 52rem;
}

.hero-img-container {
  width: 30%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.hero-img {
  max-width: 100%;
  height: 50vh;
  max-height: 31.25rem;
}

.primary-text {
  font-size: 2.5rem;
  margin: 0 0 1.6rem 0;
  color: #2d3748;
}

.secondary-text {
  color: #718096;
  font-size: 1.5rem;
}

.section {
  padding: 0.5rem 1rem;
}

.section.projects {
  margin-bottom: -2rem;
}

.section-break {
  border-width: 0 0 2px;
  border-color: #edf2f7;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.light-inner-break {
  border-width: 0 0 1px;
  border-color: #cbd5e0;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.section-title {
  color: #1a202c;
  font-weight: 600;
  font-size: 1.625rem;
  margin: 0.625rem 0 0.9375rem;
}

.projects-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.work-list {
  max-width: 40.5rem;
}

.company-name {
  font-size: 1.1875rem;
  font-weight: 500;
  color: #2d3748;
}

.work-title {
  margin: 0.3125rem 0;
}

.work-title-period {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.work-description-list {
  margin: 0.625rem 0;
}

.footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
  padding-bottom: 2rem;
}

.footer-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer-link {
  text-decoration: none;
  font-size: 1rem;
  color: #4299e1;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #4299e1;
  padding: 1px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  font-weight: 500;
  margin-right: 0.5rem;
  line-height: 1.4;
}

.footer-copyright {
  color: #718096;
  font-size: 0.875rem;
}

/* Responsive styles */

@media only screen and (max-width: 667px) {
  .primary-text {
    font-size: 2rem;
    margin: 0 0 1.3rem 0;
  }

  .secondary-text {
    font-size: 1.2rem;
  }
  .footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    /* justify-content: center; */
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .footer-links {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .App {
    padding: 0 1rem;
  }
  .navbar {
    height: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 2rem 1rem 1rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-brand {
    margin-bottom: 0.625rem;
  }

  .navbar-nav {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    width: 100%;
    margin: 0.5rem 0;
    padding: 0;
    font-size: 0.75rem;
  }
  .nav-item:not(:last-child) {
    margin-right: 0;
  }

  .nav-link {
    font-size: 0.875rem;
  }

  .primary-text {
    font-size: 1.5rem;
    margin: 0 0 1rem 0;
  }

  .hero {
    min-height: 18rem;
    height: -webkit-calc(100vh - 15rem);
    height: calc(100vh - 15rem);
  }
  .secondary-text {
    font-size: 1.1rem;
  }

  .section {
    padding: 0.5rem 0.5rem;
  }
  .section.projects {
    margin-bottom: -1rem;
  }

  .section-break {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .section-title {
    font-weight: 600;
    font-size: 1.4rem;
    margin: 0.5rem 0 0.75rem;
  }
  .company-name {
    font-size: 1.1rem;
  }

  .work-title-period {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .work-title {
    margin: 0.125rem 0;
  }

  .work-description-list {
    margin: 0.5rem 0 1.25rem;
    font-size: 0.925rem;
  }

  .light-inner-break {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
